import { render, staticRenderFns } from "./detailv2.vue?vue&type=template&id=28b6003a&scoped=true&"
import script from "./detailv2.vue?vue&type=script&lang=js&"
export * from "./detailv2.vue?vue&type=script&lang=js&"
import style0 from "./detailv2.vue?vue&type=style&index=0&id=28b6003a&prod&lang=css&"
import style1 from "./detailv2.vue?vue&type=style&index=1&id=28b6003a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b6003a",
  null
  
)

export default component.exports